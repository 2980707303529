import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        appStoreIcon: file(relativePath: { eq: "app-store-badge.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <a href={'https://play.google.com/store/apps/details?id=com.kasra.natalchartsapp'}>
        <Img fluid={data.appStoreIcon.childImageSharp.fluid}
          alt={'Download on the App Store'}
          fadeIn={false}
        />
      </a>
    )}
  />
)
export default Image
