import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        googlePlayIcon: file(relativePath: { eq: "google-play-badge.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <a href={'https://play.google.com/store/apps/details?id=com.kasra.natalchartsapp'}>
        <Img fluid={data.googlePlayIcon.childImageSharp.fluid}
          alt={'Get it on Google Play'}
          fadeIn={false}
        />
      </a>
    )}
  />
)
export default Image
