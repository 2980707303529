import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GooglePlayBadge from "../components/GooglePlayBadge"
import AppStoreBadge from "../components/AppStoreBadge"

const IndexPage = () => {
  require('./index.scss')

  return (
    <Layout className={'home-page'}>
      <SEO title="Natal Charts Notebook" isIndex />
      <div>
        <h1>Natal Charts Notebook</h1>
        <ul>
          <li>Gain in depth knowledge of your birth chart.</li>
          <li>Store birth charts of others, friends, new partners.</li>
          <li>Learn how best to interact with yourself and others.</li>
          <li>Analyze the different parts of people's personalities.</li>
        </ul>
        <div className={'cta-container'}>
          <GooglePlayBadge />
          <AppStoreBadge />
        </div>
        <div className={'links-container'}>
          <Link to={'/privacy'}>Privacy Policy</Link>
          <span>｜</span>
          <Link to={'/contact'}>Contact</Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
